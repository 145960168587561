// Keep in sync with LoanApplicationStatus in backend
export const LoanApplicationStatus = {
    pending: 'pending',
    preQualified: 'preQualified',
    preQualificationAccepted: 'preQualificationAccepted',
    incomeVerification: 'incomeVerification',
    incomeVerified: 'incomeVerified',
    offered: 'offered',
    accepted: 'accepted',
    declined: 'declined',
    approved: 'approved',
    denied: 'denied',
    deniedIndividualCanAddCoApplicant: 'deniedIndividualCanAddCoApplicant',
    humanInvestigate: 'humanInvestigate',
    ineligible: 'ineligible',
    incomplete: 'incomplete',
    duplicate: 'duplicate',
    mobileNotary: 'mobileNotary', // application is in a county that requires RIN
}

// Keep in sync with NextCryptoApplicationAction in aven_backend/src/controller/originationReturnController.ts
export const NextCryptoApplicationAction = {
    // Applicant should be directed to the Thanks page
    THANKS: 'thanks',
    // Applicant should view the offer preview page
    OFFER_PREVIEW: 'offerPreview',
    // Applicant is already a customer so they should view the Welcome page
    WELCOME: 'welcome',
    DENIED: 'denied',
    PENDING: 'pending',
    REVIEW: 'review',
    // Identity verification with Persona for crypto product
    IDENTITY_VERIFICATION: 'identityVerification',
    // Signature process in place of notarization for crypto product
    SIGN_DOCUMENTS: 'signDocuments',
}

// Keep in sync with MAX_LINE_SIZE_CRYPTO in aven_python/src/policy/basedCryptoPolicy/basedCryptoParameters.py
export const MAX_LINE_SIZE_CRYPTO = 100_000
